//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2024 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

/***** Auto-complete ****

div.autocomplete
  position: absolute
  width: 400px
  margin: 0
  padding: 0
  z-index: 500
  ul
    list-style-type: none
    margin: 0
    padding: 0
    li
      list-style-type: none
      display: block
      margin: -1px 0 0 0
      padding: 2px
      cursor: pointer
      font-size: 90%
      border: 1px solid var(--color-border-muted)
      border-left: 1px solid var(--color-border-muted)
      border-right: 1px solid var(--color-border-muted)
      background-color: white
      &.selected
        background-color: #ffb
      span.informal
        font-size: 80%
        color: #aaa

#parent_issue_candidates ul li, #related_issue_candidates ul li
  width: 500px

// -------------------------- ng-select --------------------------
.ng-select
  width: 100%
  font-size: var(--body-font-size)

  &.-error
    .ng-select-container
      border-color: var(--content-form-error-color)

  .ng-value-container
    min-height: 2rem
    width: calc(100% - 30px)

    // Override OPs default padding for inputs which looks strange in the autocompleter
    input
      padding-left: 0px

  .ng-select-container
    border-radius: 2px
    z-index: auto !important
    height: 30px !important
    min-height: 30px !important
    border-color: var(--color-border-default) !important

    .ng-value-container
      overflow: visible !important

      .ng-placeholder
        top: 1px !important
        color: var(--color-fg-muted)
        @include text-shortener

      input
        height: 100%
        padding: 0
        -webkit-box-sizing: border-box !important
        -moz-box-sizing: border-box !important
        box-sizing: border-box !important
  .ng-value
    @include text-shortener
    line-height: 22px

.ng-select .ng-arrow-wrapper .ng-arrow
  border-color: var(--color-fg-muted) transparent transparent

.ng-select.ng-select-opened>.ng-select-container .ng-arrow
  border-color: transparent transparent var(--color-fg-muted)

.ng-select .ng-clear-wrapper
  color: var(--color-fg-muted)

.ng-select.ng-select-multiple .ng-select-container
  height: initial !important
  min-height: initial !important

  .ng-value-label
    display: initial !important

// Ensure dropdown is above modals
.ng-dropdown-panel
  z-index: 9500 !important

  // Overrides for the ng-footer-tmp option
  .ng-footer-text
    font-size: 0.9rem
    padding: 5px
    font-style: italic

.ng-option-label
  vertical-align: top

.ng-option
  line-height: 22px
  font-size: var(--body-font-size)

  .op-avatar
    margin-right: 8px

  &.ng-option-marked
    background-color: $spot-color-basic-gray-6 !important

.ng-dropdown-footer
  padding: 0 !important

.ng-placeholder
  line-height: 2rem

.work-package-table--container .ng-dropdown-panel
  z-index: auto !important
