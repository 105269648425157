//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2024 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

// Re-apply the primer styles with higher specificity
// as the OpenProject form styles override them.

.Box
  > ul:not(.op-uc-list)
    margin-left: 0


.FormControl
  label
    margin-bottom: 0
  input
    border-radius: 6px

action-menu
  anchored-position
    ul
      margin-left: 0

ul.tabnav-tabs
  margin-left: 0

/* Remove margin-left: 2rem from Breadcrumbs */
#breadcrumb
  ol
    margin-left: 0
  .breadcrumb-item.breadcrumb-item-selected
    a
      pointer-events: none

/* TODO: The actions within the PageHeader are currently not aligned correctly.
   The pageHeader itself already uses center alignment but the actions themselves default to 'normal'.
   Will be added to our primer repository */
.PageHeader-actions
  align-items: center

/* Primer uses the fgColor (blue) for Buttons having the :invisible schema unless they receive an trailing/leading icon.
   Then, the color changes to gray. This seems inconsistent. An issue has been opened with Primer to discuss this behavior.
   Until then, we will override the fgColor for the invisible schema.
   No all invisible schema buttons are overwritten though and need to apply this modifier themselves. */
.Button--invisibleOP,
.Button--invisibleOP:hover:not(:disabled,.Button--inactive)
  color: var(--button-invisible-fgColor-rest,var(--color-btn-outline-text))

  .Button-visual
    color: var(--button-invisible-fgColor-rest,var(--color-btn-outline-text))
